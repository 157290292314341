<template>
  <li
    class="menu-items"
    @mouseenter="state.dropdown = true"
    @mouseleave="state.dropdown = false"
  >
    <router-link
      v-if="menu.submenu"
      class="menu-category-wrapper"
      :to="menu.link"
      type="button"
      aria-haspopup="menu"
      aria-expanded="true"
    >
      <span>{{ menu.name }}</span> <span
        v-if="menu.submenu"
        class="arrow-right"
      >&raquo;</span>
    </router-link>
    <SubMenu
      v-if="menu.submenu"
      :menus="menu.submenu"
      :dropdown="state.dropdown"
      :depth-level="depthLevel"
    />
    <router-link
      v-else
      :to="menu.link"
    >
      {{ menu.name }}
    </router-link>
  </li>
</template>

<script>
import {computed, reactive} from 'vue';
import SubMenu from '@/components/Home/LeftMenu/SubMenu';

export default {
  name: 'MainMenu',
  components: { SubMenu },
  props: {
    menu: {
      type: Object,
      default: (() => {})
    },
    depthLevel: {
      type: Number,
      default() {
        return null;
      }
    }
  },

  setup(props) {
    const state = reactive({
      menus: computed(() => props.menu),
      depthLevel: 0,
      dropdown: false
    });
    const handleMouseEnter = () => {
      state.dropdown = true;
      state.depthLevel = props.depthLevel + 1;
    };

    return {
      state,
      handleMouseEnter
    };
  }
};
</script>

<style scoped>

</style>
