<template>
  <nav class="nav">
    <ul class="menus">
      <div
        v-for="(menu, index) in formattedCategories"
        :key="'menu' + index"
      >
        <MainMenu
          :menu="menu"
          :depth-level="state.depthLevel"
        />
      </div>
    </ul>
  </nav>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import routeMap from '@/utils/routeMap';
import {reactive} from 'vue';
import './LeftMenu/leftMenuCss.scss';
import MainMenu from '@/components/Home/LeftMenu/MainMenu';

export default {
  name: 'Demo',
  components: { MainMenu, SectionLoadingSpinner },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    showSectionLoader: Boolean,
  },
  setup(props) {
    const state = reactive({
      depthLevel: 0,
    });

    const formattedCategories = props.categories.map((category) => {
      return {
        id: category.id,
        name: category.name,
        link: '',
        submenu: category.sub_categories
          ? category.sub_categories.map((subCategory) => {
            return {
              id: subCategory.id,
              name: subCategory.name,
              link: '/products?category='+ category.slug +'&sub=' + subCategory.slug,
              submenu: subCategory.brands
                ? subCategory.brands.map((brand) => {
                  return {
                    id: brand.id,
                    name: brand.name,
                    link: '/products?brand=' + brand.slug,
                  };
                })
                : []
            };
          })
          : []
      };
    });

    return {
      state,
      formattedCategories,
      routeMap
    };
  }
};
</script>
<style lang="scss">
@import "LeftMenu/leftMenuCss";
</style>
