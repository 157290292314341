<template>
  <nav class="nav">
    <ul class="menus">
      <div
        v-for="(menu, index) in formattedCategories"
        :key="'menu' + index"
      >
        <MainMenu
          :menu="menu"
          :depth-level="state.depthLevel"
        />
      </div>
    </ul>
  </nav>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import routeMap from '@/utils/routeMap';
import {reactive} from 'vue';
import './LeftMenu/leftMenuCss.scss';
import MainMenu from '@/components/Home/LeftMenu/MainMenu';

export default {
  name: 'Demo',
  components: { MainMenu, SectionLoadingSpinner },
  props: {
    categories: Array,
    showSectionLoader: Boolean,
  },
  setup(props) {
    const state = reactive({
      depthLevel: 0,
      menuItems: [
        {
          title: 'Home',
          url: '/',
        },
        {
          title: 'Services',
          url: '/services',
          submenu: [
            {
              title: 'Laravel',
              url: 'web-design',
            },
            {
              title: 'web development',
              url: 'web-dev',
              submenu: [
                {
                  title: 'Frontend',
                  url: 'frontend',
                },
                {
                  title: 'Backend',
                  submenu: [
                    {
                      title: 'NodeJS',
                      url: 'node',
                    },
                    {
                      title: 'PHP',
                      url: 'php',
                    },
                  ],
                },
              ],
            },
            {
              title: 'SEO',
              url: 'seo',
            },
          ],
        },
        {
          title: 'About',
          url: '/about',
          submenu: [
            {
              title: 'web design',
              url: 'web-design',
            },
            {
              title: 'web development',
              url: 'web-dev',
              submenu: [
                {
                  title: 'Frontend',
                  url: 'frontend',
                },
                {
                  title: 'Backend',
                  submenu: [
                    {
                      title: 'NodeJS',
                      url: 'node',
                    },
                    {
                      title: 'PHP',
                      url: 'php',
                    },
                  ],
                },
              ],
            },
            {
              title: 'SEO',
              url: 'seo',
            },
          ],
        },
        {
          title: 'Services',
          url: '/services',
          submenu: [
            {
              title: 'web design',
              url: 'web-design',
            },
            {
              title: 'web development',
              url: 'web-dev',
              submenu: [
                {
                  title: 'Frontend',
                  url: 'frontend',
                },
                {
                  title: 'Backend',
                  submenu: [
                    {
                      title: 'NodeJS',
                      url: 'node',
                    },
                    {
                      title: 'PHP',
                      url: 'php',
                    },
                  ],
                },
              ],
            },
            {
              title: 'SEO',
              url: 'seo',
            },
          ],
        },
      ]
    });

    const formattedCategories = props.categories.map((category) => {
      return {
        id: category.id,
        name: category.name,
        submenu: category.sub_categories
          ? category.sub_categories.map((subCategory) => {
            return {
              id: subCategory.id,
              name: subCategory.name,
              submenu: subCategory.brands
                ? subCategory.brands
                : []
            };
          })
          : []
      };
    });

    return {
      state,
      formattedCategories,
      routeMap
    };
  },
  methods: {
    navigateToProduct( category, subCategory ) {
      const urlToGo = {name: routeMap.products.name, query: {category: category, sub : subCategory}};
      return this.$router.push(urlToGo);
    }
  }
};
</script>
<style lang="scss">
@import "LeftMenu/leftMenuCss";
</style>
