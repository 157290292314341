<template>
  <div>
    <!-- mega menu and slider -->
    <v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-overlay>

      <v-row>
        <!--left mega menu-->
        <v-col
          class="d-none d-sm-block"
          cols="12"
          sm="4"
          xl="2"
        >
          <LeftMegaMenu
            :categories="categories"
            :show-section-loader="showSectionLoader"
          />
          <!--<Demo-->
          <!--  :categories="categories"-->
          <!--      :show-section-loader="showSectionLoader"-->
          <!--/>-->
        </v-col>

        <!--main slider-->
        <v-col
          cols="12"
          sm="8"
          xl="8"
        >
          <v-row no-gutters>
            <v-col>
              <MainSlider
                :home-slides="homeSlider.items"
                :show-section-loader="showSectionLoader"
              />
            </v-col>
            <v-col>
              <!--on <xl device-->
              <v-col class="hidden-xl-only mt-7">
                <TodaysDealsHorizontal
                  :deal-slides="dealSlider.items"
                  :show-section-loader="showSectionLoader"
                />
              </v-col>
            </v-col>
          </v-row>
        </v-col>

        <!--todays deals-->
        <!--on xl device-->
        <v-col
          cols="12"
          sm="4"
          xl="2"
          class="hidden-lg-and-down"
        >
          <TodaysDeals
            :deal-slides="dealSlider.items"
            :show-section-loader="showSectionLoader"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- /mega menu and slider -->

    <!-- image Container -->
    <v-container
      v-if="bannerSection && bannerSection.sectionEnabled"
      class="pb-0"
    >
      <v-sheet
        class="mb-2 pr-3 pl-3"
        color="transparent"
      >
        <v-row
          v-if="bannerSection.settings.length"
          justify="center"
          align="center"
        >
          <v-col
            v-for="(item, index) in bannerSection.settings"
            :key="'BannerImages' + index"
            cols="12"
            sm="3"
            md="2"
            lg="2"
          >
            <v-img
              v-if="item.value_type === 'image'"
              :src="item.value"
              class="home-page-banner__image"
              contain
            />
          </v-col>
        </v-row>
        <SectionLoadingSpinner v-else-if="showSectionLoader" />
        <v-card-title v-else>
          No data Available
        </v-card-title>
      </v-sheet>
    </v-container>
    <!-- /image Container -->

    <SectionTitle
      v-if="products.length"
      class="pb-3 px-3"
      title="Latest Products"
    />

    <!--     Featured Products-->
    <v-container>
      <v-sheet color="transparent bottom-spacing">
        <!--<v-card-title class="text-center d-block title is-section-title">Latest Products</v-card-title>-->

        <div class="mt-3">
          <ProductSlider
            v-if="products.length"
            :columns="2"
            :products="products"
          />
          <SectionLoadingSpinner v-else-if="showSectionLoader" />
          <v-card-title v-else>
            No data Available
          </v-card-title>
        </div>
      </v-sheet>
    </v-container>
    <!--     Featured Products-->

    <SectionTitle
      v-if="vendorData.length"
      class="py-8 px-3"
      title="Top Vendors"
    />

    <!-- Vendors -->
    <v-container v-if="vendorData.length">
      <SliderComp
        :items="vendorData"
        key-prefix="vendor-data"
        per-page="5"
        :loop="false"
        rewind
        autoplay
        interval="2800"
        pause-on-hover
        class="bottom-spacing"
      >
        <template #slide-item="{ item }">
          <router-link :to="{name: 'Vendor', params: {slug: item.slug}}">
            <v-card
              class="mx-auto"
              color="grey lighten-4"
              max-width="600"
            >
              <v-img
                :src="item.logo"
                aspect-ratio="1"
              />
            </v-card>
          </router-link>
        </template>
      </SliderComp>
    </v-container>
    <!--    </v-parallax>-->

    <SectionTitle
      v-if="popular_products.length"
      class="py-8 px-3"
      title="Popular Products"
    />

    <!--  popular Products  -->
    <v-container v-if="popular_products.length">
      <v-sheet
        class="mb-2 mt-2"
        color="transparent bottom-spacing"
      >
        <ProductSlider
          v-if="popular_products.length"
          :columns="2"
          :products="popular_products"
        />
      </v-sheet>
    </v-container>
    <!--  /popular Products  -->
    <!-- /Vendors -->

    <SectionTitle
      v-if="brands.length"
      class="pt-5 pb-6 px-3"
      title="Available Brands"
    />

    <!-- Brands Slider -->
    <v-container
      v-if="brands.length"
      class="mb-10"
    >
      <BrandSlider :brands="brands" />
    </v-container>
    <!-- Brands Slider -->


    <!--  recently Viewed products  -->
    <SectionTitle
      v-if="recentlyViewedProductList.length"
      class="py-8 px-3"
      title="Recently viewed"
    />
    <v-container v-if="recentlyViewedProductList.length">
      <v-sheet
        class="mb-2 mt-2"
        color="transparent bottom-spacing"
      >
        <ProductSlider
          :columns="1"
          :products="recentlyViewedProductList"
        />
      </v-sheet>
    </v-container>
    <!--  /popular Products  -->
    <!--  /recently Viewed products  -->
  </div>
</template>

<script>
import ProductSlider from '@/components/Product/ProductSlider';
import BrandSlider from '@/components/Home/BrandSlider';

import {mapActions, mapGetters} from 'vuex';
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import routeMap from '@/utils/routeMap';
import SectionTitle from '@/components/Utils/SectionTitle';
import TodaysDeals from '@/components/Home/TodaysDeals';
import MainSlider from '@/components/Home/MainSlider';
import LeftMegaMenu from '@/components/Home/LeftMegaMenu';
import SliderComp from '@/components/Utils/SliderComp';
import TodaysDealsHorizontal from '@/components/Home/TodaysDealsHorizontal';
import {recentlyViewedProducts} from '@/utils/helper';
import Demo from '@/components/Home/Demo';

export default {
  name: 'Home',
  components: {
    TodaysDealsHorizontal,
    Demo,
    SliderComp,
    LeftMegaMenu,
    MainSlider,
    TodaysDeals,
    SectionTitle,
    SectionLoadingSpinner,
    BrandSlider,
    ProductSlider
  },

  data: () => ({
    routeMap,
    showSectionLoader: true,
    slideOptions: {
      type: 'loop',
      rewind: true,
      perPage: 5,
      gap: '1rem',
      autoplay: true,
      pagination: false,
      pauseOnHover: true,
      // autoWidth: true,
      breakpoints: {
        640: {
          perPage: 2,
          gap: '.7rem',
          height: 'auto',
        },
        480: {
          perPage: 1,
          gap: '.7rem',
          height: 'auto',
        },
      },
    },

    options: {
      'centerMode': true,
      'centerPadding': '20px',
      'focusOnSelect': true,
      'infinite': true,
      'slidesToShow': 3,
      'speed': 500
    },
    recentlyViewedProductList: [],
  }),

  computed: {
    ...mapGetters({
      dataAvailable: 'home/hasData',
      homeSlider: 'home/mainSlider',
      dealSlider: 'home/dealSlider',
      categories: 'home/categories',
      loading: 'home/loading',
      homeBanner: 'home/homeBanner',
      brands: 'home/brands',
      products: 'home/products',
      vendorData: 'home/vendors',
      popular_products: 'home/popular_products',
      pageSectionContent: 'pages/getPageSection',
    }),

    bannerSection() {
      return this.pageSectionContent('home', 'banner', 'show_banner');
    },

    urlParams() {
      return this.$route.fullPath;
    }
  },

  watch: {
    urlParams: {
      handler() {
        this.updateRecentlyViewed();
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    ...mapActions({
      getHomePageData: 'home/getData'
    }),

    updateRecentlyViewed() {
      const data = recentlyViewedProducts();
      this.recentlyViewedProductList = data;
    }
  },
  async mounted() {

    if (!this.dataAvailable) {
      await this.getHomePageData();
    }

    this.updateRecentlyViewed();

    setTimeout(() => this.showSectionLoader = false, 2500);
  }

};
</script>
<style lang="scss">
.home-page-banner__image {
  transition: all .3s ease-in-out;

  .v-image__image {
    transition: all .3s ease-in-out;
  }

  &:hover {
    transition: all .3s ease-in-out;

    .v-image__image {
      transition: all .3s ease-in-out;
      background-size: 110%;
    }
  }
}

.mega-menu-container {
  border-right: 1px solid #ddd;
}

.deal-item-container {
  @media only screen and (max-width: 600px) {
    height: 100%;
  }
  height: 288px;
}

@media only screen and (max-width: 600px) {
  .is-section-title {
    &::before, &::after {
      width: 20% !important;
    }
  }
}

.bottom-spacing {
  li {
    margin-bottom: 5px;
  }
}
</style>
