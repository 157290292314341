<template>
  <ul
    class="dropdown"
    :class="{
      'dropdown-submenu' : state.depthLevel > 1,
      'show' : dropdown
    }"
  >
    <div
      v-for="(menu, index) in state.menus"
      :key="'menu' + index"
    >
      <MainMenu
        :menu="menu"
        :depth-level="depthLevel+1"
      />
    </div>
    <!--<MenuItems :menus="menus" :depthLevel="state.depthLevel"/>-->
  </ul>
</template>

<script>
import {computed, reactive} from 'vue';

export default {
  name: 'SubMenu',
  components: {
    MainMenu: () => import('@/components/Home/LeftMenu/MainMenu')
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      }
    },
    depthLevel: {
      type: Number,
      default() {
        return null;
      }
    },
    dropdown: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  setup(props) {
    const state = reactive({
      menus: computed(() => props.menus),
      dropdown: props.dropdown,
      depthLevel: props.depthLevel+1,
    });

    return {
      state
    };
  }
};
</script>

<style scoped>

</style>
