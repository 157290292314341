<template>
  <v-carousel
    v-if="(slides && slides.length)"
    height="auto"
    hide-delimiter-background
    show-arrows-on-hover
    continuous
    cycle
    interval="3500"
  >
    <v-carousel-item
      v-for="(slide) in slides"
      :key="slide.id"
      eager
    >
      <router-link :to="slide.actionLink">
        <v-img
          :src="slide.image"
          contain
          eager
        />
      </router-link>
    </v-carousel-item>
  </v-carousel>

  <SectionLoadingSpinner v-else-if="showSectionLoader" />
  <v-card-title v-else>
    No data Available
  </v-card-title>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import {isMobile} from 'mobile-device-detect';
import {computed} from 'vue';

export default {
  name: 'MainSlider',
  components: { SectionLoadingSpinner },
  props: {
    homeSlides: {
      type: Array,
      default: () => []
    },
    showSectionLoader: Boolean
  },
  setup(props) {
    const slides = computed(() => {

      const slides = [];

      props.homeSlides.forEach((slide) => slides.push({
        id: slide.id,
        caption: slide.caption,
        actionLink: slide.action_link,
        actionText: slide.action_text,
        image: isMobile ? (slide.mobile_image?.url || null) : slide.image
      }));

      // strip out that doesn't have image
      return slides.filter(slide => slide.image);
    });

    return {
      slides
    };
  }
};
</script>

<style scoped>

</style>
