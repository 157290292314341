<template>
  <v-carousel
    v-if="(dealSlides && dealSlides.length)"
    height="auto"
    hide-delimiters
    show-arrows-on-hover
    continuous
    cycle
    interval="5000"
    progress
    vertical
  >
    <div class="mb-1 text-h6">
      Todays Deals
    </div>
    <v-carousel-item
      v-for="(itm, index) in dealSlides"
      :key="index"
    >
      <router-link :to="itm.action_link">
        <v-img
          :src="itm.image"
          contain
        />
        <div
          v-if="itm.action_text"
          class="d-flex justify-center py-1"
        >
          <span>{{ itm.action_text }}</span>
        </div>
      </router-link>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';

export default {
  name: 'TodaysDeals',
  components: { SectionLoadingSpinner },
  props: {
    dealSlides: Array,
    showSectionLoader: Boolean
  },
};
</script>

<style scoped>

</style>
