<template>
  <div
    v-if="(dealSlides && dealSlides.length)"
    class=""
  >
    <SliderComp
      :items="dealSlides"
      key-prefix="todays-deal-horizontal-slide"
      per-page="5"
      autoplay
      interval="6000"
      rewind
      :loop="false"
      :breakpoints="{
        600: { // xs
          perPage: 2
        },
        960: { // sm
          perPage: 3
        },
        1264: { // md
          perPage: 4
        }
      }"
    >
      <template #slide-item="{ item }">
        <router-link :to="item.action_link">
          <v-img
            :src="item.image"
            :alt="item.title"
            min-height="140px"
            min-width="140px"
            aspect-ratio="1"
            eager
          />
        </router-link>
      </template>
    </SliderComp>
  </div>

  <!--<v-carousel-->

  <!--  height="auto"-->
  <!--  hide-delimiters-->
  <!--  show-arrows-on-hover-->
  <!--  continuous-->
  <!--  cycle-->
  <!--  interval="3000"-->
  <!--  progress-->
  <!--&gt;-->

  <!--  <v-carousel-item-->
  <!--    v-for="(itm, index) in dealSlides.items"-->
  <!--    :key="index"-->
  <!--  >-->
  <!--    <router-link :to="itm.action_link">-->
  <!--      <v-img :src="itm.image" contain />-->
  <!--      <div-->
  <!--        v-if="itm.action_text"-->
  <!--        class="d-flex justify-center py-1"-->
  <!--      >-->
  <!--        <span>{{ itm.action_text }}</span>-->
  <!--      </div>-->
  <!--    </router-link>-->
  <!--  </v-carousel-item>-->
  <!--</v-carousel>-->
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import SliderComp from '@/components/Utils/SliderComp';

export default {
  name: 'TodaysDealsHorizontal',
  components: { SliderComp, SectionLoadingSpinner },
  props: {
    dealSlides: Array,
    showSectionLoader: Boolean
  },
};
</script>

<style scoped>

</style>
